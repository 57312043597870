@import "style/mixins.module.scss";

.root {
    position: relative;
}

.icon-country {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.modal {
    width: 300px;
    max-height: 500px;
    overflow-y: auto;
    background-color: #ffffff;
    position: absolute;
    left: -16px;
    z-index: var(--z-index-modal);
    list-style: none;
    top: 50px;
    box-shadow: 0 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 8px;

    @include respond-to("laptop") {
        width: 400px;
    }
}
