@import "style/mixins.module.scss";

.root {
    position: relative;
}

.placeholder {
    pointer-events: none;
    opacity: 0.6;

    position: absolute;
    transform: translate(0) scale(1);
    left: 36px;
    top: 0;

    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;

    z-index: 2;

    &_has-value {
        top: -8px;
    }

    @include color("gray", "-4");

    @include respond-to("laptop") {
        &_light {
            @include color("gray", "0");
        }
    }
}

.theme {
    &_light {
        .icon-search,
        .icon-arrow {
            path {
                stroke: get_color("gray", "0");
            }
        }

        .address-clinic {
        .button-select-sities {
            @include color("gray", "0");
        }

        .placeholder {
            @include color("opacities", "white-7");
            opacity: 1;
        }
    }
  }
}

.icon-search {
    position: absolute;
    left: 0;
    top: 16px;
    pointer-events: none;
}

.icon-arrow {
    color: transparent;
    position: absolute;
    top: 16px;
    right: 0;
    pointer-events: none;
    transition: 0.2s all linear;

    &_active {
        transform: rotate(180deg);
    }
}

.button-select-sities {
    width: 100%;
    background-color: transparent;
    height: 55px;
    padding-left: 36px;
    border-radius: 0;
    text-align: left;
    border: none;
    border-bottom: 1px solid rgba(66, 78, 88, 0.1);
    font-family: inherit;
    font-size: 17px;
    line-height: 24px;
    
    @include color("gray", "-4");

    @include respond-to("laptop") {
        display: none;
    }

    &_error {
        border-bottom: 1px solid get_color("primary", "red");
    }
}
