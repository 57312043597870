@import "style/mixins.module.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* TODO: https://github.com/smile2impress/frontend/issues/78 */
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.body {
    position: relative;
    max-width: 100%;
    overflow: auto;
    padding: 0 8px;

    @include respond-to("tablet") {
        height: auto;
        max-height: 100%;
        max-width: 720px;
        overflow: visible;
        padding: 0;
    }

    &_right {
        position: absolute;
        right: 0;
        height: 100%;
        width: 50%;
        padding: 24px;
        max-width: none;
        background-color: get_color("gray", "0");
    }

    &_bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 32px 24px 24px;
        background-color: get_color("gray", "0");
        border-radius: 8px 8px 0 0;
        max-width: none;
    }

    &_full {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: get_color("gray", "0");
        max-width: none;
    }
}

.close-button {
    position: absolute;
    top: 10px;
    right: 18px;
    width: 24px;
    height: 24px;
    padding: 0;
    -webkit-appearance: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }

    &:after,
    &:before {
        content: "";
        top: 11px;
        left: 5px;
        position: absolute;
        height: 2px;
        width: 14px;
        background-color: get_color("gray", "-4");
    }

    &:after {
        transform: rotate(-45deg);
    }
    &:before {
        transform: rotate(45deg);
    }
}

@media (min-width: 768px) {
    .overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .close-button {
        top: -35px;
        right: -35px;
        width: 32px;
        height: 32px;
        background-color: transparent;
        border-radius: 50%;

        &:after,
        &:before {
            top: 15px;
            left: 9px;
            height: 2px;
            width: 14px;
            background-color: get_color("gray", "0");
        }
    }
}
