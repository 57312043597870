@import "style/mixins.module.scss";

.root {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 24px;
    cursor: pointer;
    border-radius: 8px;
    background: get_color("gray", "0");
    border: 2px solid rgba(66, 78, 88, 0.1);

    &:last-child {
        margin-bottom: 0;
    }

    &_checked {
        border-color: get_color("primary", "red");
    }
}
