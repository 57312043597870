@import "style/mixins.module.scss";

.root {
    position: relative;
    border: none;
    width: 100%;
    cursor: pointer;
    display: block;
    border: 1px solid rgba(get_color("gray", "-4"), 0.1);
    border-radius: 16px;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 48px;
    padding-bottom: 16px;
    transition: 0.3s ease-out;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover input:not(:checked) ~ .checkmark {
        @include respond-to("mobile") {
            background-color: get_color("primary", "red");
        }

        &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: get_color("gray", "0");

            @include respond-to("laptop") {
                background-color: get_color("gray", "-2");
            }
        }
    }

    &_checked {
        border-color: transparent;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    }
}

.radio {
    position: absolute;
    top: 16px;
    right: 16px;
}
