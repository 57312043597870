@import "style/mixins.module.scss";

.theme {
    &_light {
        .label {
            @include color("gray", "0");
        }

        .item-icon {
            @include color("gray", "0");
        }
    }
}

.error-text {
    margin-top: 11px;
    margin-bottom: 0;
    font-size: 13px;
    @include color("primary", "red");
    text-align: left;
    padding-left: 0;
}

.item-icon {
    overflow: hidden;
    border-radius: 50%;
    @include color("gray", "-4");
}
