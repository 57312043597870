@import "style/mixins.module";

$action-height: 128px;

.checkbox-is-accept {
    margin-bottom: 24px;
}

.mobile-button {
    display: flex;
    justify-content: center;
    height: 56px;
    font-family: inherit;
    line-height: 36px;

    @include respond-to("laptop-L") {
        display: none;
    }
}

.desktop-button {
    display: none;

    @include respond-to("laptop-L") {
        display: flex;
        justify-content: center;
        font-family: inherit;
    }
}

.select-cities {
    margin-bottom: 44px;

    @include respond-to("laptop") {
        margin-bottom: 24px;
    }
}

.mobile-action-wrapper_is-fixed {
    margin-top: calc(#{$action-height} + 20px);

    @include respond-to("laptop-L") {
        margin: 0;
    }
}

.mobile-action_is-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    height: $action-height;
    width: 100%;
    padding: 16px 24px;
    background: get_color("gray", "0");
    box-shadow: 0 6px 14px -6px rgb(24 39 75 / 12%),
        0 10px 32px -4px rgb(24 39 75 / 10%);
    text-align: center;
    z-index: 10;

    @include respond-to("laptop-L") {
        display: none;
    }
}
