@import "style/mixins.module.scss";

.root {
    display: block;
    position: relative;
    height: 24px;
    width: 24px;
    min-width: 24px;
    cursor: pointer;
    user-select: none;

    &:hover .radio-input ~ .checkmark-input {
        &:after {
            display: block;
        }
    }

    & .radio-input:checked ~ .checkmark-input:after {
        display: block;
    }
}

.checkmark-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-color: var(--color-light-gray);
  border-radius: 12px;

  &_checked {
    background-color: get_color("primary", "red");

      &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: get_color("gray", "0");
      }      
  }
}

.radio-input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark-input {
      background-color: get_color("primary", "red");

      &::after {
            content: "";
            position: absolute;
            display: none;
            top: 8px;
            left: 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: get_color("gray", "0");
      }      
    }
}
