@import "style/mixins.module.scss";

.label {
    position: absolute;
    left: 8px;
    top: 19px;
    font-size: 13px;
    line-height: 20px;

    @include color("gray", "-4");

    @include respond-to("laptop") {
        right: 27px;
        left: auto;
    }
}

.placeholder {
    pointer-events: none;
    opacity: 0.6;

    position: absolute;
    transform: translate(0) scale(1);
    left: 36px;
    top: 0;

    z-index: 2;

    @include color("gray", "-4");

    @include respond-to("laptop") {
        top: -8px;

        &_light {
            @include color("gray", "0");
        }
    }
}

.icon-search {
    position: absolute;
    left: 0;
    top: 22px;
    pointer-events: none;

    @include respond-to("laptop") {
        &_light {
            @include color("gray", "0");
        }
    }
}

.icon-arrow {
    color: transparent;
    position: absolute;
    display: none !important;
    top: 22px;
    right: 0;
    pointer-events: none;
    transition: 0.2s all linear;

    &_active {
        transform: rotate(180deg);
    }

    @include respond-to("laptop") {
        display: block;

        &_light {
            path {
                stroke: get_color("gray", "0");
            }
        }
    }
}

.error-text {
    margin-top: 11px;
    font-size: 13px;
    text-align: left;
    padding-left: 0;

    @include color("primary", "red");
}

.container-select {
    position: relative;
}

.button-clear {
    position: absolute;
    top: 16px;
    right: 0;
    padding: 0;
    border: none;
    background: transparent;
    z-index: 105;

    @include respond-to("laptop") {
        display: none;
    }
}
