@import "style/mixins.module.scss";

.root {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 8px;
    overflow-x: hidden;
    background-color: get_color("gray", "0");
    z-index: var(--z-index-modal);
    width: 100%;
}

.item {
    position: relative;
    display: flex;
    padding: 16px;

    @include color("gray", "-4");
    white-space: nowrap;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;

    cursor: pointer;

    &__icon {
        display: inline-flex;
        align-items: center;
        margin-right: 11px;
        width: 24px;
        height: 24px;
    }
}

.icon {
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.item-phone-code {
    margin-right: 8px;
}

.item_active {
    position: relative;
    @include color("primary", "red");
}

.item_active:after {
    content: "";
    position: absolute;
    top: 22px;
    right: 24px;
    display: block;
    width: 17px;
    height: 13px;
    background: url(/images/icons/activeRegion.svg) no-repeat center center;
    background-size: contain;
}

@media (min-width: 768px) {
    .item {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .item_active:after {
        top: 19px;
        width: 13px;
        height: 9px;
    }
}
